import { MutationTree, Module, GetterTree } from "vuex";
import "firebase/auth";
import { GlobalState } from "./state";
import { Role } from "./users";
import { User } from "firebase/auth";

export type AuthState = {
  currentUser?: User;
  currentRoles?: Role[];
  error?: Error;
};

const mutations: MutationTree<AuthState> = {
  CLEAR_USER(state) {
    state.currentUser = undefined;
  },
  SET_USER(state, user: User) {
    state.currentUser = user;
  },
  SET_ERROR(state, err: Error) {
    state.error = err;
  },
  CLEAR_ERROR(state) {
    state.error = undefined;
  },
  CLEAR_ALL(state) {
    state.currentUser = undefined;
    state.currentRoles = undefined;
  },
  SET_ROLES(state, roles: Role[]) {
    state.currentRoles = roles;
  },
};

const getters: GetterTree<AuthState, GlobalState> = {
  userName({ currentUser }) {
    if (currentUser) {
      return currentUser.displayName;
    }
  },
  isSuperAdmin({ currentRoles, currentUser }): boolean {
    if (!currentUser || !currentRoles) {
      return false;
    }
    return currentRoles.findIndex((c) => c.role == "superAdmin") >= 0;
  },
  isClientAdmin({ currentRoles, currentUser }): boolean {
    if (!currentUser || !currentRoles) {
      return false;
    }
    return (
      currentRoles.findIndex(
        (c) => c.role == "clientAdmin" || c.role == "superAdmin"
      ) >= 0
    );
  },
  isCompanyAdmin({ currentRoles, currentUser }): boolean {
    if (!currentUser || !currentRoles) {
      return false;
    }
    return currentRoles.findIndex((c) => c.role == "companyAdmin") >= 0;
  },
  isLoggedIn(state): boolean {
    const val =
      state.currentUser != undefined && (state.currentRoles ?? []).length > 0;
    return val;
  },
};

const state: AuthState = {
  currentRoles: [],
  currentUser: undefined,
  error: undefined,
};

export const authModule: Module<AuthState, GlobalState> = {
  mutations,
  state,
  getters,
  namespaced: true,
};
