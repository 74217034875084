import Vue from "vue";
import Vuex from "vuex";

import { initializeApp } from "firebase/app";
import "firebase/compat/auth";

import calls from "./calls";
import companies from "./companies";
import users from "./users";
import { authModule as auth } from "./auth";
import { GlobalState } from "./state";
import { UserError } from "@/lib/error";

Vue.use(Vuex);

const config = {
  apiKey: "AIzaSyDJXI-lWy-nqjo-DQdpQsEtCOtlAEJEr1k",
  authDomain: "auth.genius.ipcom.ai",
};
initializeApp(config);

export default new Vuex.Store<GlobalState>({
  state: {
    lastError: undefined,
  },
  mutations: {
    SET_ERROR(state, err: UserError) {
      state.lastError = err;
    },
    CLEAR_ERROR(state) {
      state.lastError = undefined;
    },
  },
  modules: {
    calls,
    companies,
    auth,
    users,
  },
});
