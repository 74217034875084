import { ArbitroUser, Role } from "@/store/users";
import { authClient } from "./client";
import {
  AuthProvider,
  browserLocalPersistence,
  GoogleAuthProvider,
  OAuthProvider,
  setPersistence,
  Unsubscribe,
  getAuth,
  signInWithRedirect,
  User,
  onAuthStateChanged,
} from "firebase/auth";
import { FromError, AuthError, UserError } from "./error";

export async function startLogin(type: AuthTypes) {
  const auth = getAuth();
  let provider: AuthProvider;
  if (type === "google") {
    provider = new GoogleAuthProvider();
  } else if (type === "microsoft") {
    provider = new OAuthProvider("microsoft.com");
  } else {
    return;
  }

  try {
    await setPersistence(auth, browserLocalPersistence);
    await signInWithRedirect(auth, provider);
  } catch (err) {
    throw AuthError(err);
  }
}

export function recoverAuth(): Promise<AuthUser | undefined> {
  let unsub: Unsubscribe;
  const auth = getAuth();
  return new Promise<AuthUser | undefined>((resolve) => {
    unsub = onAuthStateChanged(auth, (user) => {
      if (user) {
        handleUser(user)
          .then((user) => resolve(user))
          .catch(() => resolve(undefined));
      } else {
        resolve(undefined);
      }
    });
  }).finally(() => {
    if (unsub) {
      unsub();
    }
  });
}

export async function handleUser(user: User | null): Promise<AuthUser> {
  if (!user) {
    throw new UserError("usuario desconocido");
  }
  const token = await user.getIdToken();
  const roles = await getUserRoles(token);
  if (roles.length === 0) {
    throw new UserError("Usuario sin permisos");
  }
  return {
    roles,
    user,
  };
}

async function getUserRoles(token: string): Promise<Role[]> {
  const client = authClient(token);
  try {
    const auser = await client.get("me").json<ArbitroUser>();
    return auser.roles;
  } catch (err) {
    throw await FromError(err);
  }
}

export type AuthTypes = "google" | "microsoft" | "email";
export type AuthUser = {
  user: User;
  roles: Role[];
};
