import ky from "ky";
import store from "../store";

export class UserError extends Error {
  public reqid?: string;
  public internalError?: Error;
  public retryOp?: RetryOperation;

  constructor(message: string, err?: Error, reqid?: string) {
    super(message);
    this.internalError = err;
    this.name = "UserError";
    this.reqid = reqid;
  }
  public get UserMessage(): string {
    return `${this.message} ${this.reqid ? "reqid: " + this.reqid : ""}`;
  }
}
// eslint-disable-next-line
export function handleError(f: (err: UserError) => void): (er: any) => void {
  return (err) => {
    FromError(err).then(f);
  };
}
// eslint-disable-next-line
export async function FromError(err: any): Promise<UserError> {
  if (err instanceof ky.TimeoutError) {
    return new UserError("La conexión tomó demasiado tiempo", err);
  }
  if (err instanceof ky.HTTPError) {
    let msg = `Error de red: ${err.message ?? "error desconocido"}`;
    let reqid: string | undefined;
    if (err instanceof ky.HTTPError) {
      try {
        const js = await err.response.json();
        msg = js.message ?? msg;
        reqid = js.requestID;
      } catch (err) {
        // empty
      }
    }
    return new UserError(msg, err, reqid);
  }

  if (err instanceof TypeError) {
    return new UserError("Error de red", err);
  }

  if (err.code) {
    return AuthError(err);
  }

  return new UserError(err.message ?? "error general", err, err.reqid);
}

// eslint-disable-next-line
export function AuthError(err: any, reqid?: string): UserError {
  if (!err) {
    return new UserError("");
  }
  const code = err.code as string;
  let msg: string = err.message ?? "Error desconocido";
  switch (code) {
    case "auth/invalid-email":
      msg = "Email Inválido";
      break;
    case "auth/user-disabled":
      msg = "Usuario deshabilitado";
      break;
    case "auth/user-not-found":
      msg = "Usuario no encontrado";
      break;
    case "auth/wrong-password":
      msg = "Email/Contraseña inválida";
      break;
    case "auth/auth-domain-config-required":
      msg = "Error Interno. Cod 125.";
      break;
    case "auth/operation-not-supported-in-this-environment":
      msg = "Error Interno. Cod 126.";
      break;
    case "auth/app-not-authorized":
      msg = "Error Interno. Cod 128.";
      break;
    case "auth/argument-error":
      msg = "Error Interno. Cod 129.";
      break;
    case "auth/invalid-api-key":
      msg = "Error Interno. Cod 130.";
      break;
    case "auth/unauthorized-domain":
      msg = "Error Interno. Cod 131.";
      break;
    case "auth/network-request-failed":
      msg = "Error de conexión. Revise su conexión a internet.";
      break;
  }
  return new UserError(msg, err, reqid);
}

export function handleStoreError(retry?: RetryOperation, throwAgain?: boolean) {
  // eslint-disable-next-line
  return (err: any) => {
    FromError(err).then((uerr) => {
      if (retry) {
        uerr.retryOp = retry;
      }
      store.commit("SET_ERROR", uerr);
      if (throwAgain) {
        throw uerr;
      }
    });
  };
}

export type RetryOperation = {
  name: string;
  // eslint-disable-next-line
  payload?: any;
};
