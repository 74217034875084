import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

const ignoreWarnMessage =
  "The .native modifier for v-on is only valid on components but it was used on <svg>.";
// eslint-disable-line
Vue.config.warnHandler = function (msg, vm, trace) {
  if (msg === ignoreWarnMessage) {
    msg = "";
    trace = "";
  }
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
