import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

import Login from "@/views/Login.vue";

import store from "@/store";
import { RoleTypes, Role } from "@/store/users";
import { recoverAuth } from "@/lib/auth";
import { AuthState } from "@/store/auth";

const Blacklist = () => import("@/views/Blacklist.vue");
const BlacklistReports = () => import("@/components/blacklist/list.vue");
const BlacklistAdd = () => import("@/components/blacklist/add.vue");

const Companies = () => import("../views/Companies.vue");
const CompaniesMain = () => import("../components/companies/main.vue");
const CompaniesEdit = () => import("../components/companies/editCompany.vue");
const CentersEdit = () => import("../components/companies/editCenter.vue");

const Users = () => import("@/views/Users.vue");
const UsersList = () => import("@/components/users/list.vue");
const UsersEdit = () => import("@/components/users/edit.vue");

const DailyReport = () => import("../views/DailyReport.vue");
const SalesReport = () => import("../views/SalesReport.vue");
const CSVReport = () => import("../views/ReportCSV.vue");

const Calls = () => import("../components/calls/search.vue");

const RequestResetPassword = () => import("@/views/ResetPassword.vue");

const ChangePassword = () => import("@/views/ChangePassword.vue");

const System = () => import("@/views/System.vue");
const Service = () => import("@/views/Service.vue");

const salesSearch = () => import("@/views/SalesSearch.vue");

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { noauth: true },
  },
  {
    path: "/requestPasswordReset",
    component: RequestResetPassword,
    meta: { noauth: true },
  },
  {
    path: "/user/changePassword",
    component: ChangePassword,
  },
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/blacklist",
    component: Blacklist,
    children: [
      {
        path: "",
        component: BlacklistReports,
      },
      {
        path: "new",
        component: BlacklistAdd,
      },
    ],
  },
  {
    path: "/companies",
    component: Companies,
    children: [
      {
        path: "",
        component: CompaniesMain,
      },
      {
        path: "new",
        component: CompaniesEdit,
      },
      {
        path: ":companyID",
        props: true,
        component: CompaniesMain,
      },
      {
        path: ":companyID/edit",
        props: true,
        component: CompaniesEdit,
      },
      {
        path: ":companyID/centers/new",
        component: CentersEdit,
        props: true,
      },
      {
        path: ":companyID/centers/:centerID/edit",
        props: true,
        component: CentersEdit,
      },
    ],
  },
  {
    path: "/daily-report",
    component: DailyReport,
  },
  {
    path: "/report-csv",
    component: CSVReport,
  },
  {
    path: "/sales-report",
    component: SalesReport,
  },
  {
    path: "/calls",
    component: Calls,
  },
  {
    path: "/system",
    component: System,
  },
  {
    path: "/service",
    component: Service,
  },
  {
    path: "/sales-search",
    component: salesSearch,
  },
  {
    path: "/users",
    component: Users,
    children: [
      {
        path: "",
        component: UsersList,
      },
      {
        path: "edit/:uid",
        props: true,
        component: UsersEdit,
      },
      {
        path: "new",
        component: UsersEdit,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta?.noauth) {
    checkAuth(to.meta?.roles).then((pass) => {
      if (pass) {
        next();
      } else {
        next({ path: "/login" });
      }
    });
  } else {
    next();
  }
});

function checkAuth(requiredRoles: RoleTypes[] | undefined): Promise<boolean> {
  const findRole = (cr: Role[], rr: RoleTypes[]) =>
    cr.some((r) => rr.some((r1) => r1 === r.role));
  const st = ((store.state as unknown) as { auth: AuthState }).auth;
  if (store.getters["auth/isLoggedIn"]) {
    if (!requiredRoles) {
      return Promise.resolve(true);
    }
    // eslint-disable-next-line
    return Promise.resolve(findRole(st.currentRoles!, requiredRoles));
  }
  return recoverAuth().then((user) => {
    if (!user) {
      return false;
    }
    store.commit("auth/SET_USER", user.user);
    store.commit("auth/SET_ROLES", user.roles);
    if (!requiredRoles) {
      return true;
    }
    return findRole(user.roles, requiredRoles);
  });
}

export default router;
