<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      v-if="!isLoginScreen"
    >
      <v-list dense>
        <v-list-item link color="#27E96A" to="/companies" v-if="isSuperAdmin">
          <v-list-item-action>
            <v-icon color="white">mdi-domain</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="button-list">Compañías</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link color="#27E96A" to="/blacklist" v-if="isClientAdmin">
          <v-list-item-action>
            <v-icon color="white">mdi-message-processing-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="button-list">Blacklist</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          color="#27E96A"
          to="/daily-report"
          v-if="isClientAdmin"
        >
          <v-list-item-action>
            <v-icon color="white">mdi-file-chart</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="button-list"
              >Reporte Diario</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          color="#27E96A"
          to="/sales-report"
          v-if="isClientAdmin"
        >
          <v-list-item-action>
            <v-icon color="white">mdi-finance</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="button-list"
              >Reporte de Venta</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item link color="#27E96A" to="/report-csv" v-if="isClientAdmin">
          <v-list-item-action>
            <v-icon color="white">mdi-alert-rhombus</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="button-list"
              >Reporte blacklist</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          color="#27E96A"
          link
          to="/calls"
          v-if="isClientAdmin || isCompanyAdmin"
        >
          <v-list-item-action>
            <v-icon color="white">mdi-phone-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="button-list">Llamadas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link color="#27E96A" v-if="isSuperAdmin" to="/users">
          <v-list-item-action>
            <v-icon color="white">mdi-account-group</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="button-list">Usuarios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link color="#27E96A" v-if="isSuperAdmin" to="/system">
          <v-list-item-action>
            <v-icon color="white">mdi-account-group</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="button-list">Sistema</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link color="#27E96A" v-if="isSuperAdmin" to="/service">
          <v-list-item-action>
            <v-icon color="white">mdi-account-wrench</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="button-list">Servicio</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          color="#27E96A"
          v-if="isSuperAdmin"
          to="/sales-search"
        >
          <v-list-item-action>
            <v-icon color="white">mdi-note-search</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="button-list"
              >Busqueda de ventas
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      v-if="!isLoginScreen"
    >
      <v-app-bar-nav-icon color="white" @click.stop="drawer = !drawer" />
      <v-toolbar-title>
        <span class="hidden-sm-and-down">
          <GeniusText class="margin-top-min" />
        </span>
      </v-toolbar-title>
      <v-spacer />
      <v-speed-dial v-model="userPrefs" direction="bottom">
        <template v-slot:activator>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn v-model="userPrefs" v-on="on" icon>
                <v-icon color="#fff">mdi-account-outline</v-icon>
              </v-btn>
            </template>
            <span class="text-complementary-color">{{ userName }}</span>
          </v-tooltip>
        </template>
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              class="mx-2"
              fab
              dark
              small
              color="#27E96A"
              v-on="on"
              @click="userSettings"
            >
              <v-icon dark> mdi-cog </v-icon>
            </v-btn>
          </template>
          <span class="text-primary-color">Cambiar contraseña</span>
        </v-tooltip>

        <v-btn class="mx-2" fab dark small color="#27E96A" @click="logout">
          <v-icon>mdi-login</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-app-bar>
    <v-main>
      <v-container fluid style="height: 100%">
        <router-view></router-view>
        <global-error />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { signOut, getAuth } from "firebase/auth";
import { mapGetters, mapState } from "vuex";
import GlobalError from "./components/globalError.vue";

import GeniusText from "./assets/Logo-with-text.svg";
export default {
  components: {
    GlobalError,

    GeniusText,
  },
  computed: {
    ...mapGetters("auth", [
      "isSuperAdmin",
      "isCompanyAdmin",
      "isClientAdmin",
      "userName",
    ]),
    ...mapState("auth", ["currentUser"]),
    isLoginScreen() {
      return this.$route.meta?.noauth;
    },
  },
  methods: {
    userSettings() {
      this.$router.push("/user/changePassword");
    },
    logout() {
      const auth = getAuth();
      signOut(auth).finally(() => {
        this.$store.commit("auth/CLEAR_ALL");
        this.$router.push("/login");
      });
    },
  },
  data: () => ({
    drawer: null,
    userPrefs: false,
  }),
};
</script>

<style scoped lang="scss">
@import "./styles/global.scss";

header {
  background: $background-principal !important;
}

nav {
  background: $background-principal !important;
  border-top: 5px solid #27e96a;
}

main {
  background: $background-tertiary !important;
}
</style>
