import * as Vuex from "vuex";
import { client } from "@/lib/client";
import { GlobalState } from "./state";
import ky from "ky";
import { handleStoreError } from "@/lib/error";

const CompanyModule: Vuex.Module<CompanyCenterState, GlobalState> = {
  namespaced: true,
  state: {
    companies: [],
    centers: [],
    loadingCompany: false,
    loadingCompanies: false,
  },
  mutations: {
    SET_COMPANIES(state, payload: Company[]) {
      state.companies = payload;
    },
    SET_COMPANY(state, company: Company) {
      const idx = state.companies.findIndex((v) => v.id === company.id);
      if (idx === -1) {
        state.companies.push(company);
      } else {
        state.companies[idx] = company;
      }
    },
    SET_CENTERS(state, payload: CallCenter[]) {
      const tmpCenters: CallCenter[] = state.centers;
      for (const center of payload) {
        const idx = tmpCenters.findIndex((c) => c.id === center.id);
        if (idx === -1) {
          tmpCenters.push(center);
        } else {
          tmpCenters[idx] = center;
        }
      }
      state.centers = tmpCenters;
    },
    SET_CENTER(state, center: CallCenter) {
      const idx = state.centers.findIndex((v) => v.id === center.id);
      if (idx === -1) {
        state.lastCenterInserted = center;
        state.centers.push(center);
      } else {
        state.centers.splice(idx, 1, center);
      }
    },
  },
  actions: {
    getCompanies({ commit }) {
      return client
        .get("companies")
        .json<Company[]>()
        .then((companies) => {
          commit("SET_COMPANIES", companies);
        })
        .catch(handleStoreError({ name: "companies/getCompanies" }));
    },
    getCenters({ commit }, companyId: number) {
      return client
        .get(`companies/${companyId}/centers`)
        .json<CallCenter[]>()
        .then((centers) => {
          commit(
            "SET_CENTERS",
            centers.map((c) => ({ ...c, companyId }))
          );
        })
        .catch(
          handleStoreError({
            name: "companies/getCenters",
            payload: companyId,
          })
        );
    },
    addCenter({ commit }, payload: addCenterPayload) {
      return client
        .post(`companies/${payload.companyId}/centers`, {
          json: payload.center,
        })
        .json<CallCenter>()
        .then((ccenter) => {
          ccenter.companyId = payload.companyId;
          commit("SET_CENTER", ccenter);
        })
        .catch(
          handleStoreError({ name: "companies/addCenter", payload }, true)
        );
    },
    addCompany({ commit }, company: Company) {
      return client
        .post(`companies`, { json: company })
        .json<Company>()
        .then((newCompany) => {
          commit("SET_COMPANY", newCompany);
        })
        .catch(
          handleStoreError(
            {
              name: "companies/addCompany",
              payload: company,
            },
            true
          )
        );
    },
    async editCenter({ commit }, payload: addCenterPayload) {
      try {
        const { center, companyId } = payload;
        delete center.companyId;
        const newCenter = await client
          .put(`companies/${companyId}/centers/${center.id}`, {
            json: center,
          })
          .json<CallCenter>();
        newCenter.companyId = center.companyId;
        commit("SET_CENTER", newCenter);
      } catch (err) {
        handleStoreError({
          name: "companies/editCenter",
          payload,
        })(err);
      }
    },
    editCompany({ commit }, company: Company) {
      return client
        .put(`companies/${company.id}`, { json: company })
        .json<Company>()
        .then((newCompany) => {
          commit("SET_COMPANY", newCompany);
        })
        .catch(
          handleStoreError(
            {
              name: "companies/editCompany",
              payload: company,
            },
            true
          )
        );
    },
    addPrefix({ commit }, payload: addPrefixPayload) {
      const { companyId, centerId, prefix } = payload;
      return client
        .post(`companies/${companyId}/centers/${centerId}/prefixes`, {
          json: prefix,
        })
        .json<CallCenter>()
        .then((center) => {
          center.companyId = companyId;
          commit("SET_CENTER", center);
        })
        .catch(
          handleStoreError(
            {
              name: "companies/addPrefix",
              payload,
            },
            true
          )
        );
    },
    removePrefix({ commit }, payload: removePrefixPayload) {
      const { companyId, centerId, prefixId } = payload;
      return client
        .delete(
          `companies/${companyId}/centers/${centerId}/prefixes/${prefixId}`
        )
        .json<CallCenter>()
        .then((center) => {
          center.companyId = payload.companyId;
          commit("SET_CENTER", center);
        })
        .catch(
          handleStoreError({
            name: "companies/removePrefix",
            payload,
          })
        );
    },
  },
};

export type addCenterPayload = { companyId: number; center: CallCenter };

export type removePrefixPayload = {
  companyId: number;
  centerId: number;
  prefixId: number;
};

export type addPrefixPayload = {
  companyId: number;
  centerId: number;
  prefix: Prefix;
};

export default CompanyModule;

export type CompanyCenterState = {
  lastCenterInserted?: CallCenter;
  companies: Company[];
  centers: CallCenter[];
  loadingCompany: boolean;
  loadingCompanies: boolean;
};

export type Company = {
  id?: number;
  name: string;
  shortName: string;
  email: string;
  active: boolean;
};

export type CallCenter = {
  id?: number;
  name: string;
  active: boolean;
  prefixes: Prefix[];
  companyId?: number;
};

export type Prefix = {
  id?: number;
  ip: string;
  prefix: string;
  record: boolean;
  skipCheck: boolean;
  response: number;
};
