import ky, { BeforeRequestHook } from "ky";

let url = "https://testdevapi.arbitro.ip-com.com";

import store from "@/store";
import { AuthState } from "@/store/auth";

if (process.env.VUE_APP_BE_URL) {
  url = process.env.VUE_APP_BE_URL;
}

const authReqHook: BeforeRequestHook = async function (request) {
  const authstore = ((store.state as unknown) as any).auth as AuthState;
  try {
    if (store.getters["auth/isLoggedIn"]) {
      const token = await authstore.currentUser!.getIdToken();
      request.headers.set("Authorization", `Bearer ${token}`);
    }
  } catch (err) {
    store.commit("auth/CLEAR_ALL");
  }
};

export const client = ky.extend({
  prefixUrl: url,
  hooks: { beforeRequest: [authReqHook] },
});

export function downloadFromBlob(blob: Blob, name: string) {
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

export function authClient(token: string) {
  return ky.extend({
    prefixUrl: url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function isAuthError(err?: ky.HTTPError): boolean {
  return err?.response?.status === 401;
}

export { url };
