import * as Vuex from "vuex";
import { client } from "@/lib/client";
import { groupBy } from "lodash-es";

const callsModule: Vuex.Module<CallStoreState, Record<string, unknown>> = {
  state: {
    loading: false,
    events: [],
  },
  getters: {
    callsTable(state) {
      const calls = groupBy(state.events, "callid");
      return Object.values(calls)
        .map((call) => {
          const initCall = call.find((v) => v.event == "initCall");
          if (!initCall) {
            return null;
          }
          const blocked = call.some((v) => v.event == "dialBlocked-limit")
            ? "limit"
            : call.some((v) => v.event == "dialBlocked-blacklist")
            ? "blacklist"
            : "";
          const stype = (call.find((v) => v.type != "<null>") || { type: "-" })
            .type;
          return { ...initCall, events: call, type: stype, blocked };
        })
        .filter((v) => v != null);
    },
    callsLoading(state) {
      return state.loading;
    },
  },
  mutations: {
    SET_CALLS(state, payload: Call[]) {
      state.events = payload;
    },
    CALLS_TOGGLE_LOADING(state) {
      state.loading = !state.loading;
    },
  },
  actions: {
    getCalls: async function getCalls({ commit }, info: CallRequestInfo) {
      commit("CALLS_TOGGLE_LOADING");
      try {
        const calls = await client.post("calls", { json: info }).json<Call[]>();
        if (Array.isArray(calls)) {
          commit("SET_CALLS", calls);
        }
      } catch (err) {
        //TODO
      }
      commit("CALLS_TOGGLE_LOADING");
    },
  },
};

export default callsModule;

export type Call = {
  timestamp: string;
  phone: string;
  callid: string;
  type: string;
  prefix: string;
  center: string;
  recUrl: string;
  event: string;
};

export type CallRequestInfo = {
  date1: string;
  date2: string;
  phone: string;
  prefix: string;
  ignoreCost?: boolean;
};

type CallStoreState = {
  loading: boolean;
  events: Call[];
};
