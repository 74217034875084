import Vue from "vue";
import Vuetify from "vuetify/lib";
import MSIcon from "@/assets/ms.svg";
import GoogleGIcon from "@/assets/g.svg";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      msft: {
        component: MSIcon,
      },
      goog: {
        component: GoogleGIcon,
      },
    },
  },
});
