import { MutationTree, GetterTree, Module, ActionTree } from "vuex";
import { GlobalState } from "./state";
import { client } from "@/lib/client";
import { handleStoreError } from "@/lib/error";
import { omit } from "lodash-es";

export type UserState = {
  users: ArbitroUser[];
  np?: string;
};

type UserList = UserState;

export type ArbitroUser = {
  email: string;
  name: string;
  uid?: string;
  active: boolean;
  roles: Role[];
};

export type SimpleRole = {
  role: "superAdmin" | "clientAdmin";
};

export type RoleWithCompany = {
  role: "companyAdmin";
  company: string;
  companyId: number;
};

export type Role = SimpleRole | RoleWithCompany;

export type RoleTypes = Role["role"];

export const availableRoles: Record<RoleTypes, string> = {
  superAdmin: "Administrador",
  companyAdmin: "Admin Compañía",
  clientAdmin: "Admin Cliente",
};

export function isRoleWithCompany(r: Role): r is RoleWithCompany {
  return r !== undefined && (r as RoleWithCompany).companyId !== undefined;
}

const mutations: MutationTree<UserState> = {
  ADD_USERS(state, users: ArbitroUser[]) {
    state.users = state.users.concat(users);
  },
  REPLACE_USER(state, user: ArbitroUser) {
    const idx = state.users.findIndex((u) => u.uid === user.uid);
    if (idx == -1) {
      return;
    }
    state.users.splice(idx, 1, user);
  },
  SET_USERS(state, users: ArbitroUser[]) {
    state.users = users;
  },
  SET_NP(state, np: string) {
    state.np = np;
  },
};

const getters: GetterTree<UserState, GlobalState> = {
  moreUsersAvailable(state) {
    return !!state.np;
  },
};

const actions: ActionTree<UserState, GlobalState> = {
  async listUsers({ commit }) {
    const res = await client.get("users").json<UserList>();
    commit("SET_USERS", res.users);
    commit("SET_NP", res.np);
  },
  async getMoreUsers({ state, commit }) {
    if (!state.np) {
      return;
    }
    const users = await client
      .get("users", { searchParams: { np: state.np } })
      .json<ArbitroUser>();
    commit("ADD_USERS", users);
  },
  async createUser({ commit }, payload: ArbitroUser) {
    const newUser = await client
      .post("users", { json: payload })
      .json<ArbitroUser>()
      .catch(handleStoreError(undefined, true));
    commit("ADD_USERS", [newUser]);
  },
  async updateUser({ commit }, user: ArbitroUser) {
    const uid = user.uid!;
    const payload = omit(user, ["uid"]);
    const newUser = await client
      .put(`users/${uid}`, { json: payload })
      .json<ArbitroUser>()
      .catch(handleStoreError(undefined, true));
    commit("REPLACE_USER", newUser);
  },
  toggleUser({ dispatch }, payload: toggleUser) {
    return client
      .post(`users/${payload.uid}/active`, { json: { active: payload.active } })
      .then(() => {
        dispatch("listUsers");
      })
      .catch(
        handleStoreError({ name: "users/disableUsers", payload: payload })
      );
  },
};

export type toggleUser = {
  uid: string;
  active: boolean;
};

const userModule: Module<UserState, GlobalState> = {
  state: {
    users: [],
    np: undefined,
  },
  mutations,
  getters,
  actions,
  namespaced: true,
};

export default userModule;
